



// (function ($) {
const activeSlide = $(".catalogue .active");
const slide = $(".catalogue .slide");
const slideTop = $(".catalogue .slide-top");
const slideBottom = $(".catalogue .slide-bottom");
const slideImg = $(".catalogue .slide-pack");
const slideNavPrev = $(".prev");
const slideNavNext = $(".next");
const hero = $(".catalogue");

function init() {

  TweenLite.set(slide.not(activeSlide), { autoAlpha: 0, });
  TweenLite.set(slideBottom.not(activeSlide), { autoAlpha: 0, y: '100%', });
  TweenLite.set(slideImg.not(activeSlide), { autoAlpha: 0, y: '100%' });

  TweenLite.set(slideNavPrev, { autoAlpha: 0.2 });

}
// Run Init function
init();

function goToNextSlide(slideOutTop, slideInTop, slideOutBottom, slideInBottom, slideInImg, slideOutImg) {
  const tl = new TimelineLite();
  const slideOutH1 = slideOutTop.find('.slide-content');
  const slideInH1 = slideInTop.find('.slide-content');
  const slideInBottomBg = slideInBottom.find('.scroll-bg');

  const index = slideInTop.index();
  const size = $('.catalogue-top .slide').length;
  if (slideInTop.length !== 0) {
    tl
      .set([slideInBottom, slideInImg], { autoAlpha: 1, className: '+=active' })
      .set(slideInTop, { y: '0%', autoAlpha: 1, className: '+=active' })

      .set([slideOutTop, slideOutBottom, slideOutImg], { className: '-=active' })

      .to([slideOutH1], 0.3, { y: '-=50px', autoAlpha: 0, ease: Power3.easeInOut }, 0)

      .to(slideOutBottom, 0.8, { y: '-100%', ease: Power3.easeInOut }, 0)
      .to(slideInBottom, 0.8, { y: '0%', ease: Power3.easeInOut }, -0.1)

      .to(slideOutImg, 0.8, { y: '-200%', ease: Power3.easeInOut }, 0)
      .to(slideInImg, 0.8, { y: '0%', ease: Power3.easeInOut }, 0)

      .fromTo([slideInH1], 0.5, { y: '+=50px', autoAlpha: 0 }, { autoAlpha: 1, y: 0, ease: Power1.easeInOut }, 0.01);
  }
}

function goToPreviousSlide(slideOutTop, slideInTop, slideOutBottom, slideInBottom, slideInImg, slideOutImg) {
  const tl = new TimelineLite();
  const slideOutH1 = slideOutTop.find('.slide-content');
  const slideInH1 = slideInTop.find('.slide-content');
  // const slideInImg = slideIn.find('.slide-content');
  const index = slideInTop.index();
  const size = $('.catalogue-top .slide').length;

  if (slideInTop.length !== 0) {
    tl
      .set([slideInBottom, slideInImg], { y: '-100%', autoAlpha: 1, className: '+=active' })
      .set(slideInTop, { y: '0%', autoAlpha: 1, className: '+=active' })

      .set(slideOutTop, { y: '0%', autoAlpha: 0, className: '+=active' })
      .set([slideOutTop, slideOutBottom, slideOutImg], { className: '-=active' })

      .to([slideOutH1], 0.3, { y: '+=50px', autoAlpha: 0, ease: Power3.easeInOut }, 0)

      .to(slideOutBottom, 0.8, { y: '100%', ease: Power3.easeInOut }, 0)
      .to(slideInBottom, 0.8, { y: '0%', ease: Power3.easeInOut }, -0.1)

      .to(slideOutImg, 0.8, { y: '+200%', ease: Power3.easeInOut }, 0)
      .to(slideInImg, 0.8, { y: '0%', ease: Power3.easeInOut }, 0)

      .fromTo([slideInH1], 0.5, { y: '-=50px', autoAlpha: 0 }, { autoAlpha: 1, y: 0, ease: Power1.easeInOut }, 0.01);

  }

}

slideNavNext.click(function (e) {
  e.preventDefault();
  const slideOutTop = $('.slide-top.active');
  const slideInTop = $('.slide-top.active').next('.slide-top');
  const slideOutBottom = $('.slide-bottom.active');
  const slideInBottom = $('.slide-bottom.active').next('.slide-bottom');
  const slideOutImg = $('.slide-pack.active');
  const slideInImg = $('.slide-pack.active').next('.slide-pack');

  goToNextSlide(slideOutTop, slideInTop, slideOutBottom, slideInBottom, slideInImg, slideOutImg);


});

slideNavPrev.click(function (e) {
  e.preventDefault();

  const slideOutTop = $('.slide-top.active');
  const slideInTop = $('.slide-top.active').prev('.slide');
  const slideOutBottom = $('.slide-bottom.active');
  const slideInBottom = $('.slide-bottom.active').prev('.slide');
  const slideOutImg = $('.slide-pack.active');
  const slideInImg = $('.slide-pack.active').prev('.slide-pack');
  goToPreviousSlide(slideOutTop, slideInTop, slideOutBottom, slideInBottom, slideInImg, slideOutImg);

});

function slideNext() {
  console.log('slide next');
  const slideOutTop = $('.slide-top.active');
  const slideInTop = $('.slide-top.active').next('.slide-top');
  const slideOutBottom = $('.slide-bottom.active');
  const slideInBottom = $('.slide-bottom.active').next('.slide-bottom');
  const slideOutImg = $('.slide-pack.active');
  const slideInImg = $('.slide-pack.active').next('.slide-pack');

  goToNextSlide(slideOutTop, slideInTop, slideOutBottom, slideInBottom, slideInImg, slideOutImg);

}
function slidePrev() {
  console.log('slide prev');
  const slideOutTop = $('.slide-top.active');
  const slideInTop = $('.slide-top.active').prev('.slide');
  const slideOutBottom = $('.slide-bottom.active');
  const slideInBottom = $('.slide-bottom.active').prev('.slide');
  const slideOutImg = $('.slide-pack.active');
  const slideInImg = $('.slide-pack.active').prev('.slide-pack');
  goToPreviousSlide(slideOutTop, slideInTop, slideOutBottom, slideInBottom, slideInImg, slideOutImg);

}

function addEvent(el, eventType, handler) {
	if (el.addEventListener) {
		el.addEventListener(eventType, handler, { passive: false });
	} else if (el.attachEvent) {
		el.attachEvent('on' + eventType, handler, { passive: false });
	} else {
		el['on' + eventType] = handler;
	}
};

const lethargy = new Lethargy(7, 100, 0.05);

const checkScroll = function (e) {

  e.preventDefault()
  e.stopPropagation();
  var t = lethargy.check(e);
  if(!1 !== t ){
    if (t === 1) {
      // $.throttle(800, true, slidePrev)
      slidePrev();
      return
    }
    if (t === -1) {

      slideNext()
      return

    }

  }

};

			addEvent(window, 'scroll', $.throttle(1200, true,
        checkScroll) );
			addEvent(window, 'mousewheel',  $.throttle(1200, true,
        checkScroll));
			addEvent(window, 'DOMMouseScroll',  $.throttle(1200, true,
        checkScroll));
			addEvent(window, 'wheel', $.throttle(1200, true,
        checkScroll));
			addEvent(window, 'MozMousePixelScroll',  $.throttle(1200, true,
        checkScroll));


















// function delay(n) {
//   n = n || 2000;
//   return new Promise(done => {
//     setTimeout(() => {
//       done();
//     }, n)
//   })
// }

// basic default transition (with no rules and minimal hooks)

// barba.init({
//   sync: true,
//   transitions: [{
//     leave({ current, next, trigger }) {
//       // do something with `current.container` for your leave transition
//       // then return a promise or use `this.async()`
//       alert('leavw')
//     },
//     enter({ current, next, trigger }) {
//       // do something with `next.container` for your enter transition
//       // then return a promise or use `this.async()`
//       alert('enter')
//     }
//   }]
// });